<template>
  <section class="products">
    <v-img
      src="https://ctk-website.oss-cn-shanghai.aliyuncs.com/citiking/znm/banner.png"
      class="top-banner"
    >
      <v-layout
        align-center
        fill-height
        justify-center
        style="position: relative; text-align: center;"
      >
      </v-layout>
    </v-img>
    <v-container  grid-list-md>
      <v-layout wrap mt-6 mt-md-12 mt-lg-12 mt-xl-12>
        <v-flex xs12 sm12 md6 lg6 xl6 pr-md-8 pr-lg-8 pr-xl-8 style="background:#F6F6F6;display:flex;align-items: center; max-height:270px; padding-top: 30px; padding-bottom:30px;">
          <v-img
            max-height="90"
            src="https://ctk-website.oss-cn-shanghai.aliyuncs.com/citiking/znm/dlt.png"
            contain
            aspect-ratio="1"
            position="center center"
          >
          </v-img>
        </v-flex>
        <v-flex xs12 sm12 md6 lg6 xl6 pl-md-8 pl-lg-8 pl-xl-8 pt-8 pt-md-0 pt-lg-0 pt-xl-0>
          <h5 class="znm-title">自粘膜</h5>
          <p class="znm-p" style="margin-top: 20px;margin-bottom:0px;">{{desc}}</p>
          <v-img
            max-height="40"
            src="https://ctk-website.oss-cn-shanghai.aliyuncs.com/citiking/znm/p_bg.png"
            contain
            aspect-ratio="1"
            position="left"
          >
          </v-img>
        </v-flex>
      </v-layout>
      <v-layout wrap mt-6 mt-md-12 mt-lg-12 mt-xl-12>
        <v-flex xs12 sm12 md12 lg12 xl12>
          <div class="znm-title"><img class="title-icon" src="https://ctk-website.oss-cn-shanghai.aliyuncs.com/citiking/znm/case_icon.png" /><span>应用场景</span></div>
        </v-flex>
        <v-flex xs12 sm12 md3 lg3 xl3 pl-2 pr-2 mt-4
          v-for="(f, fi) in fields"
          :key="fi"
        >
          <v-img
            class="field-img"
            :src="f.img"
            aspect-ratio="1"
          >
          </v-img>
          <p class="znm-p">{{f.title}}</p>
        </v-flex>
      </v-layout>
      <v-layout wrap mt-6 mt-md-12 mt-lg-12 mt-xl-12 mb-12>
        <v-flex xs12 sm12 md12 lg12 xl12>
          <div class="znm-title"><img class="title-icon" src="https://ctk-website.oss-cn-shanghai.aliyuncs.com/citiking/znm/video_icon.png"/><span>相关视频</span></div>
        </v-flex>
        <v-flex xs12 sm12 md6 lg6 mt-4>
          <video style="padding:0;" class="top-banner" id="player" controls poster="">
            <source src="https://citiking-web.oss-cn-beijing.aliyuncs.com/video/%E8%87%AA%E7%B2%98%E8%86%9C%E5%B7%A5%E8%89%BA%E6%B5%81%E7%A8%8B.mp4" type="video/mp4" />
          </video>
          <p class="znm-p">自粘膜工艺流程</p>
        </v-flex>
      </v-layout>
    </v-container>
  </section>
</template>
<script>
export default {
  data: () => ({
    page: 1,
    cid: 9,
    desc: '',
    pageSize: 100,
    fields: []
  }),
  created () {
    document.querySelector('#menu_product').classList.add('v-btn--active')
    this.getCategory()
    this.getFields()
  },
  mounted () {
  },
  watch: {
  },
  computed: {
  },
  methods: {
    getCategory () {
      this.https.get('field_category', { id: this.cid }).then(response => {
        if (response.code === 0) {
          this.desc = response.data.cat.desc
        }
      })
    },
    getFields () {
      this.https.get('fields', { page: this.page, cid: this.cid, pageSize: this.pageSize }).then(response => {
        if (response.code === 0) {
          this.fields = response.data.data
        }
      })
    }
  }
}
</script>
<style>
.znm-title {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  height: 24px;
  color: #000000;
}
.znm-title span{
  display: inline-block;
  height: 24px;
  line-height: 24px;
}
.znm-p {
  font-style: normal;
  font-weight: 350;
  font-size: 16px;
  line-height: 32px;
  color: #666666;
  margin-bottom: 0px !important;
}
.title-icon {
  float: left;
  height: 24px;
  margin-right: 10px;
  vertical-align:middle;
}
.field-img {max-height:220px}
@media(max-width:960px){
  .field-img {max-height:190px}
}
</style>
